import homeService from "../api/homePageService";
export default {
    data() {
        return {
            isDropTask: true
        }
    },
    methods: {
        // 放弃任务，好像只有点击返回才有用
        async dropTask() {
            if (!this.hb || !this.hb.taskId) {
                return;
            }
            return homeService.dropTask(this.$route.query.taskId).then(() => {
                this.$Message.success("放弃成功！");
            });
        },
        dropTaskOnUnload(e) {
            // 关闭和刷新的调用，不用考虑isDropTask
            if (!this.$route.query.taskId) {
                return;
            }
            this.dropTask().then(() => {
                // 利用了chrome 弹框时阻止进程的特点，如果用户取消了离开页面才继续执行脚本，重新领取！
                // 经过测试 edge 浏览器没有chrome的这个特点，弹框没有任何阻塞！ 在非chrome上可能无法实现这个功能！
                homeService.receiveTask(this.$route.query.taskId).catch(() => {
                    this.$Modal.error({
                        content: '任务已被其他人领取，请返回列表！',
                        okText: '返回',
                        onOk: () => {
                            this.isDropTask = false;
                            this.$router.back()
                        }
                    })

                })
            })
            e.returnValue = "o/";
        }
    },
    mounted() {
        window.addEventListener("beforeunload", this.dropTaskOnUnload);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener("beforeunload", this.dropTaskOnUnload)
        // 已放弃，删除和提交 不要放弃，各个组件均已做了设置 isDropTask 为 false
        // 除了编辑页面，所有的页面均有 taskId (编辑页面有创建，重写，回收站。无需放弃)        
        if (this.isDropTask && (this.$route.query.taskId)) {
            this.$Spin.show()
            this.dropTask().then(() => {
                this.$Spin.hide()
                next()
            });
        } else {
            next()
        }
    },

}