export default {
    provide() {
        return {
            voiceSexByParagraphKey: []
        };
    },
    methods: {
        initVoiceSexConfig(hb, audioLinesMap) {
            // 新需求 对于中文精读的古诗词，需要设置解析和赏析的配音，为男声或女声，且所有断句要同步变化
            if ("cnIntensive" !== hb.contentType) {
                return;
            }
            const specialChapterCodes = [];
            if ("cnIntensivePoetry" === hb.category) {
                // 中文精读的古诗词 的 赏析和解析，需要指定男声女声  
                // 添加需求：古诗词的正文,也要指定男女声（古诗词所有都要指定）
                // 添加需求：古诗词的 朝代，作者和标题，也要指定男女声，并且和正文共享一个声音字段 // dynasty author t，需求已取消
                specialChapterCodes.push("appreciate", "example", 'content');
            }
            if ("cnIntensiveWisdom" === hb.category) {
                // 中文精读的名言警句 的 注释，需要指定男声女声
                specialChapterCodes.push("explanation");
            }
            if (!specialChapterCodes.length) {
                return;
            }
            let resArr = [];
            for (let i = 0; i < audioLinesMap.length; i++) {
                let lines = audioLinesMap[i].filter(ele => /^p\d+-\d+$/.test(ele.paragraphKey));
                let chapterCodes = hb.attributeList[i].contentDivList.map(
                    ele => ele.chapterCode
                );
                let res = [];
                for (let line of lines) {
                    let ind = line.paragraphKey.replace(/^p(\d+)-\d+$/, "$1");
                    res[ind] = res[ind] || {
                        paragraphKeys: [],
                        chapterCode: chapterCodes[ind],
                        showVoiceSex: specialChapterCodes.includes(chapterCodes[ind]),
                        voiceSex: line.voiceSex || "male"
                    };
                    res[ind].paragraphKeys.push(line.paragraphKey);
                }
                res = res.filter(ele => !!ele && ele.showVoiceSex);
                resArr.push(res);
            }
            let buf = this._provided.voiceSexByParagraphKey;
            buf.splice(0, buf.length, ...resArr);
        },
        writeVoiceSex({ audioMap, audioCommpareReturn }) {
            let buf = this._provided.voiceSexByParagraphKey;
            let arcitleLen = buf.length
            for (let i = 0; i < arcitleLen; i++) {
                let vs = buf[i]
                let ls = audioMap && audioMap[i] || []
                let sls = audioCommpareReturn && audioCommpareReturn[i] || []
                if (ls && ls.length) {
                    ls.forEach(l => {
                        let v = vs.find(ele => ele.paragraphKeys.includes(l.paragraphKey));
                        if (v) {
                            l.voiceSex = v.voiceSex
                        }
                    })
                }
                if (sls && sls.length) {
                    sls.forEach(sl => {
                        let v = vs.find(ele => ele.paragraphKeys.includes(sl.paragraphKey));
                        if (v) {
                            sl.voiceSex = v.voiceSex
                        }
                    })

                }
            }
        }
    }
}