// @ts-check
const utils = {
    _html2lines(html) {
        let div = document.createElement('div')
        // 去掉img 图片, 避免图片加载报错！
        div.innerHTML = html.replace(/<img.*?>/g, '')
        let pList = div.getElementsByTagName('p')
        let res = [];
        for (let i = 0; i < pList.length; i++) {
            let pHtmls = pList.item(i).innerHTML
                .split(/<br\/?>/)
                .map(str => str.replace(/&nbsp;/g, ' ').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&'))
                .filter(str => str.trim());
            for (let j = 0; j < pHtmls.length; j++) {
                res.push({ str: pHtmls[j], key: 'p' + i + '-' + j })
            }
        }
        return res;
    },
    _text2lines(str) {
        let items = []
        let buf = ''
        str.split('').forEach(char => {
            if (/\n/.test(char) && buf.trim()) {
                items.push(buf)
                buf = ''
            } else {
                buf += char
            }
        })
        if (buf.trim()) {
            items.push(buf)
        }
        return items
    },

    * linesReduceIte(lines) {
        let key = "", text = "";
        for (let line of lines) {
            const { paragraphKey, content, onebest } = line
            if (key === paragraphKey) {
                text += content || onebest
                continue
            }
            if (key) {
                yield { paragraphKey: key, text }
            }
            key = paragraphKey
            text = content || onebest
        }
        if (key) {
            yield { paragraphKey: key, text }
            key = ""
            text = ""
        }

    }
}

const audioLinesStrategy = {
    _readFromVerseContent(verse) {
        let lines = []
        for (let i = 0; i < verse.backGroundList.length; i++) {
            let page = verse.backGroundList[i]
            for (let j = 0; j < page.divElementList.length; j++) {
                let textDiv = page.divElementList[j]
                let divContent = textDiv.divContent
                let items = utils._text2lines(divContent)
                items.forEach((ele, ind) => {
                    lines.push({
                        text: ele,
                        audio: null,
                        paragraphKey: textDiv.paragraphKey || 'p' + i + '-' + j + '-' + ind,
                        unread: false,
                        matched: false,
                        unreadFlags: new Array(ele.length).fill(false)
                    })
                })

            }
        }
        if ("cnVerse" != verse.contentType || "cnVerseProse" != verse.category) {
            // 中文韵文 古诗词除外
            lines.unshift({
                text: verse.title,
                audio: null,
                paragraphKey: "t",
                unread: false,
                matched: false,
                unreadFlags: new Array(verse.title.length).fill(false)
            });
        }
        return lines;
    },
    _writeToVerseContent(verse, lines) {
        for (let { paragraphKey, text } of utils.linesReduceIte(lines)) {
            if (['contentSource', 'dynasty', 'author'].includes(paragraphKey)) {
                verse.attributeList[0][paragraphKey] = text
            } else if ('t' === paragraphKey) {
                verse.title = text
            } else {
                const [pi, di, li] = paragraphKey.replace('p', '').split('-')
                const divObj = verse.backGroundList[pi].divElementList[di]
                if (!divObj.bufItems) {
                    divObj.bufItems = []
                }
                divObj.bufItems[li] = text
            }
        }
        for (let page of verse.backGroundList) {
            for (let divObj of page.divElementList) {
                if (divObj.bufItems) {
                    divObj.divContent = divObj.bufItems.join('\n')
                    divObj.bufItems = undefined
                    continue;
                }
                if (!divObj.bufItems && !['contentSource', 'dynasty', 'author', 't'].includes(divObj.paragraphKey)) {
                    // 被删除的
                    divObj.divContent = ''

                    continue
                }
                // 特殊被忽略的
                let key = divObj.paragraphKey
                divObj.divContent = 't' === key ? verse.title : verse.attributeList[0][key]
            }
        }
    },
    /**
     * 根据传入的绘本、韵文、句子等的完整信息，生成前端定义的配音数据模型
     * 会采用多种生成策略 如有audioMap，韵文无audioMap，绘本无audioMap等
     * @param {*} hb 
     * @param {number} i 
     * @returns {Array<Array>}  paragraphKey t表示标题，
     */
    _readFromViewContent(hb, i) {
        let lines = utils._html2lines(hb.mdContentList[i]);
        lines = lines.map(({ str, key }) => {
            return {
                text: str,
                audio: null,
                paragraphKey: key,
                unread: false,
                matched: false,
                unreadFlags: new Array(str.length).fill(false)
            };
        });
        if ('cnView' === hb.contentType && ('cnViewProse' === hb.category || 'cnViewModernPoetry' === hb.category)) {
            // 中文绘本 小古文 要添加来源
            let csI = hb.attributeList?.[i]?.contentSource || ''
            if (csI.trim()) {
                lines.push({
                    text: csI,
                    audio: null,
                    paragraphKey: 'contentSource',
                    unread: false,
                    matched: false,
                    unreadFlags: new Array(csI.length).fill(false)
                })
            }
        }
        if (hb.contentType !== 'enSentence') {
            // 英文句子标题没有配音
            // 英文绘本里的好词好句，没有标题配音  // && ("enView" !== hb.contentType || "enViewSentence" != hb.category) // 需求更改：改为有标题配音
            let titleI = hb.contentTitle[i] && hb.contentTitle[i].content || ''
            if (titleI.trim()) {
                lines.unshift({
                    text: titleI,
                    audio: null,
                    paragraphKey: 't',
                    unread: false,
                    matched: false,
                    unreadFlags: new Array(titleI.length).fill(false)
                })
            }
        }
        return lines;

    },
    _writeToViewContent(hb, lines, i) {
        const div = document.createElement('div')
        div.innerHTML = hb.mdContentList[i]
        const plist = div.getElementsByTagName('p')
        const plsArr = Array.prototype.map.call(plist, () => [])

        for (let { paragraphKey, text } of utils.linesReduceIte(lines)) {
            if (['contentSource', 'dynasty', 'author'].includes(paragraphKey)) {
                hb.attributeList[i][paragraphKey] = text
            } else if ('t' === paragraphKey) {
                hb.contentTitle[i].content = text
            } else {
                const [pi, li] = paragraphKey.replace('p', '').split('-')
                if (pi >= plsArr.length) {
                    throw new Error('数据错误：音频段落多于文案段落。')
                }
                plsArr[pi][li] = text.replace(/&/g, '&amp;').replace(/\s/g, '&nbsp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
            }
        }
        for (let p of plist) {
            p.innerHTML = plsArr.shift().join('<br>')
        }
        hb.mdContentList[i] = div.innerHTML

    },

    _readFromGameContent(hb, i) {
        let comb = hb.contentMaterialList[i]
        let lines = comb.materialList.map((ele, i) => ({
            text: ele.content,
            paragraphKey: i + '',
            audio: null,
            voiceSex: 'male',
            unread: false,
            matched: false,
            unreadFlags: new Array(ele.content.length).fill(false)
        }))
        if ("cnIdiomComb" !== hb.contentType) {
            lines.unshift({
                text: hb.title,
                paragraphKey: 't',
                audio: null,
                voiceSex: 'male',
                unread: false,
                matched: false,
                unreadFlags: new Array(hb.title.length).fill(false)
            })
        }
        return lines
    },
    _writeToGameContent(hb, lines, i) {
        let divs = hb.contentMaterialList[i].materialList
        let buf = []
        for (let { paragraphKey, text } of utils.linesReduceIte(lines)) {
            if ('t' === paragraphKey) {
                hb.title = text
            } else {
                buf[paragraphKey] = text
            }
        }
        divs.forEach((ele, i) => {
            ele.content = buf[i] || ''
        })
    },

    _readFromIntensive(hb, i) {
        const lines = [];
        let titleI = hb.contentTitle[i]?.content ?? ''
        if (titleI.trim()) {
            lines.push({
                text: titleI,
                audio: null,
                paragraphKey: 't',
                unread: false,
                matched: false,
                unreadFlags: new Array(titleI.length).fill(false)
            })
        }
        if ('cnIntensivePoetry' === hb.category) {
            let { dynasty, author } = hb.attributeList[i]
            // 需求更改，精读古诗词的朝代和作者，合并配音：（已取消，改为分开配音）
            // dynasty = dynasty+' '+author   
            lines.push({
                text: dynasty,
                audio: null,
                paragraphKey: 'dynasty',
                unread: false,
                matched: false,
                unreadFlags: new Array(dynasty.length).fill(false)
            })
            lines.push({
                text: author,
                audio: null,
                paragraphKey: 'author',
                unread: false,
                matched: false,
                unreadFlags: new Array(author.length).fill(false)
            })
        }
        let divs = hb.attributeList[i].contentDivList
        for (let j = 0; j < divs.length; j++) {
            if (divs[j].divStyle !== "txt" || !divs[j].content.trim()) {
                continue;
            }
            // 这样有什么问题吗？ 为什么韵文不这样做？
            let items = utils._text2lines(divs[j].content)
            items.forEach((ele, ind) => {
                lines.push({
                    text: ele,
                    audio: null,
                    paragraphKey: 'p' + j + '-' + ind,
                    unread: false,
                    matched: false,
                    unreadFlags: new Array(ele.length).fill(false)
                })
            })
        }
        return lines
    },
    _writeToIntensive(hb, lines, i) {
        let divs = hb.attributeList[i].contentDivList
        let buf = []
        for (let { paragraphKey, text } of utils.linesReduceIte(lines)) {
            if (['contentSource', 'dynasty', 'author'].includes(paragraphKey)) {
                hb.attributeList[i][paragraphKey] = text
            } else if ('t' === paragraphKey) {
                hb.contentTitle[i].content = text
            } else {
                const [pi, li] = paragraphKey.replace('p', '').split('-')
                if (!buf[pi]) {
                    buf[pi] = []
                }
                buf[pi][li] = text
            }
        }
        divs.forEach((ele, i) => {
            if (ele.divStyle !== 'txt') {
                return;
            }
            ele.content = buf[i] ? buf[i].join('\n') : ''
        })
    },
}


function _initFormExisted(map, i) {
    let lines = map[i].filter(e=>e.content).map(obj => {
        let flags = new Array(obj.content.length).fill(false)
        obj.noReadIndex && obj.noReadIndex.forEach(item => {
            flags[item] = true;
        })
        return {
            text: obj.content,
            paragraphKey: obj.paragraphKey,
            voiceSex: obj.voiceSex,
            audio: obj.key
                ? { key: obj.key, url: obj.url, name: obj.name, duration: obj.duration, fileSize: obj.fileSize }
                : null,
            unread: flags.every(ele => ele),
            matched: false,
            unreadFlags: flags,
        };
    });
    return lines;
}
export function writeBack(hb) {
    let writeBackFn, articleLen;
    let linesArr = !!(hb.audioCommpareReturn?.[0]?.length) ? hb.audioCommpareReturn : hb.audioMap
    if (/Verse/.test(hb.contentType)) {
        articleLen = 1;
        writeBackFn = audioLinesStrategy._writeToVerseContent
    } else if (/Intensive/.test(hb.contentType)) {
        articleLen = hb.attributeList.length
        writeBackFn = audioLinesStrategy._writeToIntensive
    } else if (("cnIdiomComb" === hb.contentType || 'cnFlying' === hb.contentType)) {
        articleLen = hb.contentMaterialList.length
        writeBackFn = audioLinesStrategy._writeToGameContent
    } else {
        articleLen = hb.mdContentList.length
        writeBackFn = audioLinesStrategy._writeToViewContent
    }
    for (let i = 0; i < articleLen; i++) {
        writeBackFn(hb, linesArr[i], i)
    }

}
/**
 * paragraphKey t表示标题，
 * 韵文的段落格式：p2-0-1 表示第3页的第1个文本框中的第2行（会忽略空行）
 * 非韵文的段落格式：p2-1 表示第3个p标签（不会过滤空p）第2行（会忽略空行）
 * @param {*} hb 
 */
export function factory(hb) {
    let initFn, articleLen;
    if (/Verse/.test(hb.contentType)) {
        articleLen = 1;
        initFn = audioLinesStrategy._readFromVerseContent
    } else if (/Intensive/.test(hb.contentType)) {
        articleLen = hb.attributeList.length
        initFn = audioLinesStrategy._readFromIntensive
    } else if (("cnIdiomComb" === hb.contentType || 'cnFlying' === hb.contentType)) {
        articleLen = hb.contentMaterialList.length
        initFn = audioLinesStrategy._readFromGameContent
    } else {
        articleLen = hb.mdContentList.length
        initFn = audioLinesStrategy._readFromViewContent
    }

    let list = []
    for (let i = 0; i < articleLen; i++) {
        // 如果存在paragraphKey为空的也要舍弃
        if (!hb.audioMap || !hb.audioMap[i] || !hb.audioMap[i].length || hb.audioMap[i].some(ele => !ele.paragraphKey)) {
            list.push(initFn(hb, i))
        } else {
            list.push(_initFormExisted(hb.audioMap, i))
        }
    }
    return list
}
/**
 * 
 * @param {Array<Array>} linesArr 前端定义的配音数据模型，（是一个二维数组）
 * @returns {{index:[]}} 返回服务端接口需要的数据模型
 */
export function assemble(linesArr) {
    let obj = {};
    for (let i = 0; i < linesArr.length; i++) {
        obj[i] = linesArr[i].map(line => {
            let audio = line.audio || {};
            return {
                key: audio.key,
                name: audio.name,
                url: audio.url,
                duration: audio.duration,
                content: line.text,
                fileSize: audio.fileSize,
                voiceSex: line.voiceSex,
                paragraphKey: line.paragraphKey,
                noReadIndex: line.unreadFlags.reduce((pre, item, ind) => {
                    if (item) {
                        pre.push(ind);
                    }
                    return pre;
                }, [])
            };
        });
    }
    return obj;
}
export function factorySys(hb) {
    let xfs = hb.audioCommpareReturn
    if (!xfs || !xfs.length || !xfs[0] || !xfs[0].length) {
        return []
    }
    return xfs.map(arr => {
        return arr.map(ele => {
            let flags = new Array(ele.onebest.length).fill(false)
            ele.noReadIndex && ele.noReadIndex.forEach(item => {
                flags[item] = true;
            })
            return {
                paragraphKey: ele.paragraphKey,
                text: ele.onebest,
                audioDur: [+ele.bg, +ele.ed],
                matchStr: '',
                key: ele.key,
                url: ele.url,
                duration: ele.duration,
                fileSize: ele.fileSize,
                speaker: ele.speaker,
                isSelected: false,
                matched: false,
                unreadFlags: flags
            }
        })
    })
}
/**
 * @param {Array<Array<>>} sysLinesArr 
 * @param {Array<Array<>>} linesArr 
 * @param {Array<Array<>>} uLinesArr 
 */
export function assembleSys(uLinesArr, linesArr, sysLinesArr) {
    return uLinesArr.map((uLines, ind) => {
        const lines = linesArr[ind]
        const sysLines = sysLinesArr[ind]

        return uLines.map(({ i, si }) => {
            let line = lines[i]
            let sysLine = sysLines[si] || {}
            return ({
                bg: line.unread ? 0 : sysLine.audioDur[0] + '',
                ed: line.unread ? 0 : sysLine.audioDur[1] + '',
                key: sysLine.key,
                url: sysLine.key && sysLine.url,
                duration: sysLine.duration,
                fileSize: sysLine.fileSize,
                speaker: line.unread ? '' : sysLine.speaker,
                paragraphKey: line.paragraphKey,
                onebest: line.text,
                noReadIndex: line.unreadFlags.reduce((pre, item, ind) => {
                    if (item) {
                        pre.push(ind);
                    }
                    return pre;
                }, [])
            })
        })
    })

}